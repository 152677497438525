<template>
  <div class="fangdai">
    <van-nav-bar title="查询记录"
                 left-text="返回"
                 left-arrow
                 @click-left="$router.go(-1)"
                 :border="true"
                 :fixed="false" />
    <van-cell-group v-if="list.length">
      <van-cell v-for="(item,index) in list"
                :key="index"
                is-link
                :arrow-direction="item.active ? 'up' : 'down'"
                :title="'详细地址：'+item.VillageName"
                @click="cellClick(index)">
        <template #label
                  v-if="item.active">
          <div v-show="item.LandUseType">房产类型：{{item.LandUseType}}</div>
          <div v-show="item.BuildErea">面积：{{item.BuildErea}}</div>
          <div v-show="item.EleRoom">电梯房：{{item.EleRoom}}</div>
          <div v-show="item.BuildYear">建成年份：{{item.BuildYear}}</div>
          <div v-show="item.Floor">所在层数：{{item.Floor}}</div>
          <div v-show="item.TotalFloor">总层数：{{item.TotalFloor}}</div>
          <div v-show="item.UnitPrice">房产单价：{{item.UnitPrice}}</div>
          <div v-show="item.TotalPrice">房产总价：{{item.TotalPrice}}</div>
          <div v-show="item.CreateTime">查询时间：{{item.CreateTime}}</div>
        </template>
      </van-cell>
    </van-cell-group>
    <van-empty v-else
               description="暂无待查询记录" />
  </div>
</template>

<script>
import { getUserQueryInfo } from '@/api/user'

export default {
  name: 'user',
  data () {
    return {
      list: {
        VillageName: '',
        TotalPrice: '',
        UnitPrice: '',
        LandType: '',
        LandUseType: '',
        Floor: ''
      }
    };
  },
  created () {
    this.$store.state.tabBar = this.$route.name;
    this.getUserQueryInfos();
  },
  methods: {

    getUserQueryInfos () {
      getUserQueryInfo().then((res) => {
        res.data.forEach((item) => {
          item.CreateTime = item.CreateTime.replace('T', ' ');
          item.CreateTime = item.CreateTime.slice(0, 19);
          item.active = false;
        });
        this.list = res.data;
      }).catch(e => {
        console.log(e);
      })
    },
    cellClick (index) {
      this.list.forEach((item, i) => {
        if (i == index) {
          this.list[index].active = !this.list[index].active;
        } else {
          item.active = false;
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.header-wrapper {
  width: 100%;
  overflow: hidden;
  padding-bottom: 55px;
  position: relative;

  .header {
    height: 4rem;
    background: linear-gradient(to left, #09f77b, #07c160);
    border-bottom-left-radius: 100%;
    border-bottom-right-radius: 100%;
    position: relative;
    left: -10%;
    width: 120%;
    height: 180px;
    position: relative;

    .user-header {
      position: absolute;
      bottom: -30px;
      left: 50%;
      width: 80px;
      height: 80px;
      transform: translateX(-50%);
      border-radius: 50%;
      overflow: hidden;
      background-color: #fff;
      border: 4px solid #07c160;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .name {
    font-size: 14px;
    color: #323233;
    width: 100%;
    text-align: center;
    position: absolute;
    bottom: 0;
  }
}

.link-list {
  width: 95%;
  margin: 20px auto;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 0px 5px #a9a9a9;
  overflow: hidden;
}
</style>
